import Bean from "./Bean";
import {reportStatus, reportType} from "../constans/reports";
import moment from "moment";

/**
 * Klasa reprezentująca raport
 * @extends Bean
 */
class Report extends Bean {

    static createLocalReport(FarmID, type, {startDate = 0, endDate = moment().utc().endOf("day"), day = moment().utc().endOf("day")} = {}, status = reportStatus.LOADING) {
        let queryParameters = {
            farmsArray: [FarmID],
            startDate,
            endDate
        };
        if (type === reportType.STRUCTURE) {
            queryParameters = {
                farmsArray: [FarmID],
                startDate: moment(day).utc().startOf("day"),
                endDate: moment(day).utc().startOf("day")
            };
        }
        return new Report({
            FeturaQuery: {
                QueryParams: queryParameters,
                FeturaQTime: new Date().getTime(),
                QueryCode: type
            },
            QueryExecution: {
                Status: {
                    State: status
                }
            },
            isLocal: true,
            data: []
        })
    }

    updateStatus(data, status = reportStatus.READY) {
        this.QueryExecution.Status.State = status;
        this.data = data;
    }

    isOnFarm(FarmID) {
        return this.FeturaQuery.QueryParams.farmsArray.length === 1 && this.FeturaQuery.QueryParams.farmsArray.includes(FarmID);
    }

}

export default Report;