import Bean from "./Bean"
import store from "../store/store";

/**
 * Klasa reprezentująca slownik
 * @extends Bean
 */
class Dictionary extends Bean {

    /**
     * Metoda przygotowuje obiekt do zapisu, zmieniając ClientID
     * @return {ControlList}
     */
    prepareBeanToSave() {
        const state = store.getState();
        const {user: {user: {ClientID}}} = state;
        let clone = super.prepareBeanToSave();
        clone.ClientID = ClientID;
        this.setDtaModTime();
        return clone;
    }

    remove() {
        this.DtaDelTime = new Date().getTime();
        this.setDtaModTime();
    }
}

export default Dictionary;