import Bean from "./Bean"
import store from "../store/store"
import {DevType as DevTypes} from "../constans/devices";
import {myID} from "../libs/generateID";
import buildingsDB from "../database/buildingsDB";
import animalsDB from "../database/animalsDB";
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import {isNil, isArray, isString} from "lodash";

/**
 * Klasa ogólna dla urządzeń
 * @extends Bean
 */
class Device extends Bean {

    /**
     * Metoda tworząca obiket urządzenia
     * @param DevType {string}      Typ urządzenia
     * @param Address {number}      Adres urządzenia
     * @param Interface {number}    Interfejs urządzenia
     * @param Name {string}         Nazwa urządzenia
     * @param ParentID {string}     ID rodzica
     * @param VerHard {string}      Wersja sprzętu
     * @param VerSoft {string}      Wersja oprogramowania
     * @param additionalData {object}   Dodatkowe dane
     * @param Protocol {string}     Protokół urządzenia (WST, SLIP, NRF)
     * @return {object}    obiekt urządzenia
     */
    static createDevice(DevType, Address, Interface, Name, ParentID, VerHard, VerSoft, Protocol, Alias, additionalData = {}) {
        let state = store.getState();
        let dev = {
            DevID: myID(),
            Address,
            CliIDFaID_C: `${state.user.user.ClientID}+${state.location.farm}`,
            DevType,
            DtaCrtTime: +new Date(),
            DtaModTime: +new Date(),
            FarmID: state.location.farm,
            Name,
            Interface,
            ParentID,
            VerHard,
            VerSoft,
            Protocol,
            Settings: {},
            PlcmntID: [],
            Alias,
            ...additionalData
        };
        switch (DevType) {
            case DevTypes.CAGE:
                dev.Settings.Insertions = [];
                dev.Settings.Marker = [];
                break;
            case DevTypes.BRIDGE:
                delete dev.Interface;
                break;
            case DevTypes.SCALE:
                if (!dev.Siloses) {
                    dev.Siloses = new Array(8).fill(1).map((xd, index) => ({
                        Adr: index,
                        Active: false
                    }))
                }
                break;
            case DevTypes.DISPENSER:
                if (!dev.Dispensers) {
                    dev.Dispensers = new Array(20).fill(1).map((o, index) => ({
                        Adr: index,
                        Connected: false
                    }))
                }
                break;
            default:
                console.warn("Nie rozpoznano");
        }
        return dev;
        // return getDeviceClass(dev);
    }

    /**
     * Metoda pobiera listę lokalizacji, gdzie przypisane jest urządzenie
     * @return {Array}  lista lokalizacji
     */
    getLocation(index = null) {
        try {
            if (!this.PlcmntID) throw new Error("Brak PlcmntID");
            if (index !== null) {
                let plcmnts = this.PlcmntID.filter(item => item.Adr === index);
                return plcmnts.map(item => {
                    return buildingsDB.getLocationByID(item.PlcmntID);
                }).filter(item => item)
            }
            return this.PlcmntID.map(item => {
                return buildingsDB.getLocationByID(item.PlcmntID);
            }).filter(item => item)
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    removeDevice(time = new Date().getTime()) {
        this.DtaDltTime = time;
        this.setDtaModTime();
    }

    /**
     * Metoda dodaje lokalizacje umieszczenia urzadzenia
     * @param locationID {string}   ID lokalizacji
     * @param address {number|null} Adres wyjścia (dla dozowników WST i paneli wagowych WST)
     */
    addLocation(locationID, address = null) {
        if (typeof this.PlcmntID === "string") this.PlcmntID = [];
        if (!this.PlcmntID || [DevTypes.DISPENSER_NRF].includes(this.DevType)) {
            this.PlcmntID = [];
        }
        if (address !== null) {
            this.PlcmntID = this.PlcmntID.filter(item => !(item.PlcmntID === locationID && item.Adr === address));
            if ([DevTypes.DISPENSER].includes(this.DevType)) {
                this.PlcmntID = this.PlcmntID.filter(item => item.Adr !== address);
            }
        } else {
            this.PlcmntID = this.PlcmntID.filter(item => !(item.PlcmntID === locationID));
        }
        let obj = {
            PlcmntID: locationID
        };
        if (address !== null) {
            obj.Adr = address;
        }
        this.PlcmntID.push(obj);
        this.setDtaModTime();
    }

    /**
     * Metoda usuwa lokalizację dla urządzenia
     * @param locationID {string}   ID lokalizacji
     * @param address {number|null} Adres
     */
    removeLocation(locationID, address = null) {
        console.log(address, this.PlcmntID);
        if (!this.PlcmntID) throw new Error("Brak tablicy PlcmntID");
        if (address !== null) {
            console.log("is address");
            this.PlcmntID = this.PlcmntID.filter(item => item.PlcmntID !== locationID || item.Adr !== address);
        } else {
            this.PlcmntID = this.PlcmntID.filter(item => item.PlcmntID !== locationID);
        }
        this.setDtaModTime();
    }

    getAddressForLocation(locationID) {
        if (!this.PlcmntID) throw new Error("Brak tablicy PlcmntID");
        let loc = this.PlcmntID.find(item => item.PlcmntID === locationID);
        if (!loc || loc.Adr === undefined) return null;
        return loc.Adr;
    }

    /**
     * Pobiera nazwę do selecta
     * @param index         {number | null} jeżeli podany, to pobiera nazwę dla wyjścia
     * @param showAlias     {boolean}       określa czy pokazać alias urządzenia
     * @param getLongName   {boolean}       określa czy pokazać lokalizację w formacie krótkim (nazwa ostatniej lokalizacji), czy długim (budynek - sektor - komora - stanowisko)
     * @return              {string}        tekst w formacie {nazwa} {index jeżeli podano} [{adres}/{adres hex}] ({lokalizacja w wybranym formacie}) S/N: {DevID} {A: alias jezeli wybrano}
     */
    getSelectName(index = null, showAlias = true, getLongName = false) {
        let locations = this.getLocation(index);
        if (!checkIfUserIsService()) {
            return `${this.Name}${index !== null ? ` {${index}}` : ""}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""} (${locations.map(l => {
                if (getLongName) {
                    let locs = animalsDB.getAnimalLocationsByPlcmntID(l.FarmID || l.BgID || l.SID || l.CID || l.BID);
                    return locs.map(loc => loc.name).filter(item => item).join(", ");
                }
                return l ? l.CName || l.BName || l.SName || l.FarmName || l.BoxesName : undefined;
            }).filter(item => item).join(", ")})`
        }
        return `${this.Name}${index !== null ? ` {${index}}` : ""}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""} [${this.Address}/0x${this.Address.toString(16).toUpperCase()}] (${locations.map(l => {
            if (getLongName) {
                let locs = animalsDB.getAnimalLocationsByPlcmntID(l.FarmID || l.BgID || l.SID || l.CID || l.BID);
                return locs.map(loc => loc.name).filter(item => item).join(", ");
            }
            return l ? l.CName || l.BName || l.SName || l.FarmName || l.BoxesName : undefined;
        }).filter(item => item).join(", ")}) S/N: ${this.DevID}`;
    }

    getDebugName(index = null) {
        return `${this.Name} ${index !== null ? ` {${index}}` : ""} [${this.Address}/0x${this.Address.toString(16).toUpperCase()}]`;
    }

    setAnmIDInLocation(locationID, AnmID) {
        if (!this.PlcmntID) this.PlcmntID = [];
        let loc = this.PlcmntID.find(item => item.PlcmntID === locationID);
        if (loc) {
            if (!loc.AnmIDs) loc.AnmIDs = [];
            loc.AnmIDs.push(AnmID);
        }
        this.setDtaModTime();
    }

    removeAnmIDInLocation(locationID, AnmID) {
        if (!this.PlcmntID) this.PlcmntID = [];
        let loc = this.PlcmntID.find(item => item.PlcmntID === locationID);
        if (loc) {
            if (!loc.AnmIDs) loc.AnmIDs = [];
            loc.AnmIDs = loc.AnmIDs.filter(item => item !== AnmID);
        }
    }

    /**
     * Ustawia przekazane parametry konfiguracji w obiekcie urzadzenia. Nie trzeba przekazywać całego obiektu.
     * @param configuration {object}    ustawienia konfiguracji
     * @param isSet {boolean}           flaga informujaca czy udalo sie zapisac ustawienia
     */
    setConfiguration(configuration, isSet = true) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Configuration) this.Settings.Configuration = {};
        for (let key in configuration) {
            configuration[key].isSet = isSet;
            configuration[key].SetTime = new Date().getTime();
        }
        this.Settings.Configuration = {
            ...this.Settings.Configuration,
            ...configuration
        };
        this.setDtaModTime();
    }

    prepareBeanToSave() {
        let clone = super.prepareBeanToSave();
        delete clone.location;
        delete clone.GatewayID;
        return clone;
    }


    getPlacementArray(index=null) {
        const plcmntWithIndexes = isString(this.PlcmntID) ? [{PlcmntID: this.PlcmntID}] : isArray(this.PlcmntID) ? this.PlcmntID : [];
        return plcmntWithIndexes.filter(o=> isNil(index) || (o.Adr === index)).map(o=>o.PlcmntID);
    }

    getAddressString(index = null){
        return isNil(this.Address) ? "" : `${this.Address}/0x${this.Address.toString(16).toUpperCase()}`;
    }
}


export default Device;
