import Dictionary from "./Dictionary";
import {myID} from "../libs/generateID";
import {DICTIONARY_TYPE} from "../constans/general";
import store from "../store/store"

/**
 * Klasa reprezentująca lek
 * @extends Dictionary
 */
class Medicine extends Dictionary {

    /**
     * Metoda tworzy obiekt leku
     * @param Name {string}         Nazwa leku
     * @param Dose {number}         Dawka leku
     * @param Type {number}         Typ leku
     * @param Unit {string}         Jednostka dla leku
     * @param GracePeriod {number}  Okres karencji
     * @param Price {number}        Cena leku
     * @param Color {string}        Kolor oznaczenia
     * @return {Medicine}
     */
    static createMedicine(Name, Dose, Type, Unit, GracePeriod, Price, Color) {
        const state = store.getState();
        const {user: {user: {ClientID}}} = state;
        return new Medicine({
            WordID: myID(),
            DtaModTime: new Date().getTime(),
            Type: DICTIONARY_TYPE.medicine,
            ClientID: ClientID,
            WData: {Name, Dose, Type, Unit, GracePeriod, Price, Color}
        })
    }

}

export default Medicine;