import Bean from "./Bean";
import {myID} from "../libs/generateID";
import store from "../store/store"

/**
 * Podstawowa klasa notyfikacji
 * @extends Bean
 */
class Notification extends Bean {

    static createNotification(status, type, NData = {}, DtaCrtTime = new Date().getTime()) {
        const {location: {farm}} = store.getState();
        let notification = {
            DtaCrtTime,
            DtaModTime: new Date().getTime(),
            NData: {
                Status: status,
                Type: type,
                ...NData
            },
            NID: myID(),
            FarmID: farm
        };
        return new Notification(notification);
    }

}

export default Notification;