import Bean from "./Bean"
import {myID} from "../libs/generateID";
import animalsDB from "../database/animalsDB";
import groupsDB from "../database/groupsDB";

/**
 * Klasa reprezentująca zwierzę
 * @extends Bean
 */
class Animal extends Bean {

    /**
     * Usuwa ilość zwierząt z podanego miejsca. Jeżeli nie zostanie podane miejsce, wybrane zostanie pierwsze możliwe.
     * @param amount {number}                   Ilość zwierząt do usunięcia
     * @param plcmntID {string | undefined}     Miejsce, w którym są usuwane zwierzęta
     */

    /**
     * Metoda tworzy nowy obiekt zwierzęcia
     * @param AnmNo1 {string}           Numer zwierzęcia
     * @param AnimalKind {number}       Typ zwierzęcia
     * @param AnmCnt {number}           Ilość zwierząt w grupie
     * @param FarmID {string}           ID farmy, na której się znajduje
     * @param PlcmntID {string}         Miejsce świni (zmiana z tablicy na stringa, ponieważ są grupy)
     * @param additionalInfo {object}   Dodatkowe informacje
     * @return {Animal}                 Nowy obiekt zwierzęcia
     */
    static createAnimal(AnmNo1, AnimalKind, AnmCnt, FarmID, PlcmntID, additionalInfo = {}) {
        let animal = {
            AnmID: myID(),
            AnmNo1,
            AnimalKind,
            AnmCnt,
            FarmID,
            PlcmntID,
            DtaModTime: +new Date(),
            DtaInTime: +new Date(),
            ...additionalInfo
        };
        return new Animal(animal);
    }

    /**
     * Ustawia date śmierci zwierzęcia
     * @param time
     */
    setDthTime(time = new Date().getTime()) {
        this.DtaDthTime = time;
        this.setDtaModTime();
    }

    /**
     * Ustawia typ usunięcia (śmierć albo sprzedaż)
     * @param type {number}  typ usunięcia
     */
    setDthRsn(type) {
        this.DthRsn = type;
        this.setDtaModTime();
    }

    /**
     * Usunięcie czasu śmierci
     */
    removeDthTime() {
        delete this.DtaDthTime;
        delete this.DthRsn;
        this.setDtaModTime();
    }

    /**
     * Sprawdzenie czy zwierzę nie jest martwe
     * @return {boolean}
     */
    isDead() {
        return !!this.DtaDthTime;
    }

    prepareBeanToSave() {
        let clone = super.prepareBeanToSave();
        delete clone.events;
        delete clone.meta;
        delete clone.Cycles;
        delete clone.LastSeparation;
        return clone;
    }

    convertPlcmntID() {
        if (Array.isArray(this.PlcmntID) && this.PlcmntID.length === 1) {
            this.PlcmntID = this.PlcmntID[0].PlcmntID;
        }
    }

    /**
     * Metoda dodaje ilość zwierząt do podanego PlcmntID. Jeżeli nie sotanie znalezione to metoda automatycznie doda do listy
     * @param plcmntID      ID miejsca
     * @param anmCnt        Ilość zwierząt do dodania
     */
    addAnmCntToPlcmnt(plcmntID, anmCnt) {
        let foundPlcmntID = this.PlcmntID.find(item => item.PlcmntID === plcmntID);
        if (!foundPlcmntID) {
            foundPlcmntID = {
                PlcmntID: plcmntID,
                AnmCnt: 0
            };
            this.PlcmntID.push(foundPlcmntID);
        }
        this.AnmCnt += anmCnt;
        foundPlcmntID.AnmCnt += anmCnt;
        this.setDtaModTime();
    }

    addAnimals(anmCnt, plcmntID = null) {
        if (plcmntID && Array.isArray(this.PlcmntID)) {
            let foundPlcmntID = this.PlcmntID.find(item => item.PlcmntID === plcmntID);
            foundPlcmntID.AnmCnt += anmCnt;
        }
        this.AnmCnt += anmCnt;
        this.convertPlcmntID();
    }

    /**
     * Metoda usuwa zwierzęta z wybranej lokalizacji lub z pierwszej znalezionej
     * @param amount {number}       ilość zwierząt do usunięcia
     * @param plcmntID {string}     miejsce, z którego ma usunąć
     */
    removeAnimals(amount, plcmntID = undefined) {
        console.log(plcmntID, this);
        if (Array.isArray(this.PlcmntID)) {
            let plcmnt;
            if (!plcmntID) {
                plcmnt = this.PlcmntID[0];
            } else {
                plcmnt = this.PlcmntID.find(item => item.PlcmntID === plcmntID);
            }
            if (!plcmnt) throw new Error(`Nie odnaleziono miejsca w zwierzęciu o ID ${plcmntID}`);
            if (plcmnt.AnmCnt < amount) throw new Error(`W wybranym miejscu nie ma tyle zwierząt. Podano: ${amount}, max: ${plcmnt.AnmCnt}`);
            plcmnt.AnmCnt -= amount;
            if (plcmnt.AnmCnt === 0) {
                this.PlcmntID = this.PlcmntID.filter(item => item.PlcmntID !== plcmnt.PlcmntID);
            }
        }
        this.AnmCnt -= amount;
        this.convertPlcmntID();
        this.setDtaModTime();
    }

    /**
     * Metoda przenosi zwierzę do lokalizacji, zabiera z wskazanego miejsca albo z pierwszego dostępnego
     * @param plcmntID {string}                 nowa lokalizacja
     * @param amount {number}                   ilość zwierząt do przeniesienia
     * @param locationToRemoveFrom {string}     lokalizacja, z której ma zabrać zwierzęta
     * @param newAnimalNumber {string}          nowy numer zwierzęcia
     */
    transferAnimal(plcmntID, amount = 1, locationToRemoveFrom = undefined, newAnimalNumber = undefined) {
        if (amount > 0) {
            this.setDtaModTime();
            if (this.AnmCnt > 1) {
                // jezeli jest to stary sposob zapisywania Plcmnt (tablica) to usun z tej lokalizacji
                if (Array.isArray(this.PlcmntID)) {
                    if (this.PlcmntID.length > 1) {
                        console.log(this.PlcmntID);
                        let plcmnt = locationToRemoveFrom ? this.PlcmntID.find(item => item.PlcmntID === locationToRemoveFrom) : this.PlcmntID[0];
                        if (!plcmnt) throw new Error(`Nie odnaleziono miejsca w zwierzęciu o ID ${locationToRemoveFrom}`);
                        plcmnt.AnmCnt -= amount;
                        if (plcmnt.AnmCnt === 0) {
                            this.PlcmntID = this.PlcmntID.filter(item => item.PlcmntID !== plcmnt.PlcmntID);
                        }
                    }
                    this.convertPlcmntID();
                }
                // jezeli przenoszone sa wszystkie zwierzeta
                if (this.AnmCnt === amount) {
                    this.PlcmntID = plcmntID;
                } else {
                    this.AnmCnt -= amount;
                    // sprawdzenie czy w wybranej lokalizacji nie ma zwierzat z tej samej grupy
                    let animalsInLocation = animalsDB.getAllAnimalsByPlcmntID(plcmntID);
                    if (animalsInLocation.length > 0) {
                        let groups = groupsDB.getGroupWithAnimal(this.AnmID, this.FarmID);
                        let animalInSameGroup = [];
                        for (let group of groups) {
                            for (let animal of animalsInLocation) {
                                if (group.AnmIDs.includes(animal.AnmID)) {
                                    animalInSameGroup.push(animal);
                                }
                            }
                        }
                        let animalToAssign = animalInSameGroup.find(item => !item.RFID);
                        // jezeli odnaleziono zwierze z tej samej grupy i nie ma RFID to przypisz do tego zwierzecia
                        if (animalToAssign) {
                            animalToAssign.AnmCnt += amount;
                            if (Array.isArray(animalToAssign.PlcmntID)) {
                                let plcmnt = animalToAssign.PlcmntID.find(item => item.PlcmntID === plcmntID);
                                plcmnt.AnmCnt += amount;
                                this.convertPlcmntID();
                            }
                            animalToAssign.setDtaModTime();
                            return {
                                animal: animalToAssign.prepareBeanToSave(),
                                created: false
                            }
                        }
                    }
                    // utworzenie nowego zwierzecia
                    let newAnimal = this.clone();
                    newAnimal.AnmID = myID();
                    newAnimal.AnmCnt = amount;
                    newAnimal.PlcmntID = plcmntID;
                    newAnimal.OldAnmID = this.AnmID;
                    newAnimalNumber && (newAnimal.AnmNo1 = newAnimalNumber);
                    newAnimal.setDtaModTime();
                    console.log(newAnimal);
                    return {
                        animal: newAnimal,
                        created: true
                    };
                }
            } else {
                this.PlcmntID = plcmntID;
            }
        }
        this.setDtaModTime();
        return null;
        // odnalezienie danego miejjsca, z którego ma zabrac zwierzęta
        // let plcmnt;
        // if (!locationToRemoveFrom) {
        //     plcmnt = this.PlcmntID[0];
        // } else {
        //     plcmnt = this.PlcmntID.find(item => item.PlcmntID === locationToRemoveFrom);
        // }
        // //todo TŁUMACZENIA!!
        // if (!plcmnt) throw new Error(`Nie odnaleziono miejsca w zwierzęciu o ID ${locationToRemoveFrom}`);
        // if (plcmnt.AnmCnt < amount) throw new Error(`W wybranym miejscu nie ma tylu zwierząt. Podano: ${amount}, max: ${plcmnt.AnmCnt}`);
        // // pomniejszenie w odnalezionym miejscu ilości zwierząt
        // plcmnt.AnmCnt -= amount;
        // // jeżeli przeniesiono wszystkie zwierzęta z miejsca, to usunięcie miejsca
        // if (plcmnt.AnmCnt === 0) {
        //     this.PlcmntID = this.PlcmntID.filter(item => item.PlcmntID !== plcmnt.PlcmntID);
        // }
        // // sprawdzenie czy istnieje już takie plcmntID w miejscach zwierzęcia
        // let location = this.PlcmntID.find(item => item.PlcmntID === plcmntID);
        // if (location) {
        //     // jeżeli odnaleziono takie miejsce, powiększenie ilości zwierząt o tą liczbę
        //     location.AnmCnt += amount;
        // } else {
        //     // jeżeli nie odnaleziono takiego miejsca, dodanie nowego do listy zwierząt
        //     this.PlcmntID.push({
        //         PlcmntID: plcmntID,
        //         AnmCnt: amount
        //     })
        // }
    }
}

export default Animal;