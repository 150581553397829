import {controlListsPeriods} from "../constans/controlLists";
import moment from "moment";
import Dictionary from "./Dictionary";

/**
 * Klasa reprezentująca listy kontrolne
 * @extends Dictionary
 */
class ControlList extends Dictionary {

    /**
     * Metoda oznacza lub odznacza listę jako ulubioną
     * @param LocalUserID       ID użytkownika, który oznacza jako ulubiona
     */
    markAsFavorite(LocalUserID) {
        if (!Array.isArray(this.WData.Favorite)) this.WData.Favorite = [];
        if (this.WData.Favorite.includes(LocalUserID)) {
            this.WData.Favorite = this.WData.Favorite.filter(item => item !== LocalUserID);
        } else {
            this.WData.Favorite.push(LocalUserID);
        }
        this.setDtaModTime();
    }

    /**
     * Zwraca obiekt z datami startu i zakonczenia
     * @return {{start: *, end: *} | null}
     */
    getDatesForList() {
        const {WData: {Period, Day, MonthDay, StartDay, EndDay}} = this;
        let dates = {
            start: moment().startOf("day").subtract(2, "weeks")
        };
        if (Period === controlListsPeriods.X_DAYS) {
            dates.end = moment().startOf("day").add(Day, "days");
            return dates;
        }
        if (Period === controlListsPeriods.END_OF_MONTH) {
            dates.end = moment().endOf("month");
            return dates;
        }
        if (Period === controlListsPeriods.TO_DAY_IN_MONTH) {
            let day = moment().startOf("day").date(MonthDay);
            if (day.toDate().getTime() < new Date().getTime()) {
                day.add(1, "month");
            }
            dates.end = day;
            dates.start = moment().startOf("month");
            return dates;
        }
        if (Period === controlListsPeriods.RANGE) {
            return {
                start: moment.utc(StartDay).startOf("day"),
                end: moment.utc(EndDay).endOf("day")
            }
        }
        return null;
    }

}

export default ControlList;