import _ from "lodash"

/**
 * Główna klasa
 */
class Bean {

    constructor(object) {
        if (object) {
            Object.assign(this, object);
        } else {
            throw new Error('Passed empty element to Bean constructor');
        }
    }

    /**
     * Kopiuje obiekt
     * @return {*}
     */
    clone() {
        return _.cloneDeep(this);
    }

    /**
     * służy do porównywania obiektu z innym
     * @param to            drugi obiekt
     * @return {boolean}
     */
    equals(to) {
        return _.isEqual(this, to);
    }

    /**
     * Ustawia DtaModTime w obiekcie
     */
    setDtaModTime() {
        this.DtaModTime = +new Date();
    }

    /**
     * Przygotowuje obiekt do zapisu usuwając zbędne informacje
     * @return {*}
     */
    prepareBeanToSave() {
        let clone = this.clone();
        delete clone.$loki;
        return clone;
    }
}

export default Bean;