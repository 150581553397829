import Dictionary from "./Dictionary";
import {myID} from "../libs/generateID";
import {DICTIONARY_TYPE} from "../constans/general";
import store from "../store/store"

/**
 * Klasa reprezentująca składnik paszy
 * @extends Dictionary
 */
class ForageIngredient extends Dictionary {

    /**
     * Metoda tworzy obiekt leku
     * @param Name {string}         Nazwa paszy
     */
    static createIngredient(Name) {
        const state = store.getState();
        const {user: {user: {ClientID}}} = state;
        return new ForageIngredient({
            WordID: myID(),
            DtaModTime: new Date().getTime(),
            Type: DICTIONARY_TYPE.forageIngredient,
            ClientID: ClientID,
            WData: {Name}
        })
    }

}

export default ForageIngredient;