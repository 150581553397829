import {get, isArray, isNil, isObject, isString} from "lodash";
import {LogTableTypes, LogTableTypesShort} from "../constans/logCodeTypes";
import moment from "moment";
import {convertVolumeUnitTo, convertWeightUnitTo, getUnit} from "../utils/UnitUtils";
import {UnitTypes} from "../constans/unitTypes";
import {DICTIONARY_TYPE} from "../constans/general";
import {getTranslationPath} from "../utils/EventUtils";
import {getIconClassName} from "../utils/LogsViewUtils";
import i18next from "i18next";


export class LogParser {

    constructor(log, dictionaries) {
        this.log = log || {};
        this.dictionaries = dictionaries || {};
        // console.log("HELLO THERE", this);
    }

    static createLogParser(log, dictionaries = {}) {
        return new LogParser(log, dictionaries);
    }

    _getDictionaryItemByID = (dictionary = {}, path, defaultValue = "$t(none)") => {
        //mozemy path dostac jako string lub tablice
        return (isString(path) ? [path] : isArray(path) ? path : []).map((_path, index) => {
            const id = get(this.log, _path);
            const _defaultValue = (isArray(defaultValue) ? defaultValue[index] : defaultValue) || "$t(none)";
            if (isNil(id)) return _defaultValue;
            return isNil(dictionary[id]) ? _defaultValue : isObject(dictionary[id]) ? JSON.stringify(dictionary[id]) : dictionary[id];
        }).join(", ");
    }

    dateFormatter = ({path, defaultValue}) => {
        const timestamp = get(this.log, path);
        if (!timestamp) return defaultValue;
        return isFinite(timestamp) ? moment(timestamp).format("DD.MM.YY") : defaultValue;
    }

    timeFormatter = ({path, defaultValue}) => {
        const timestamp = get(this.log, path);
        if (!timestamp) return defaultValue;
        return isFinite(timestamp) ? moment(timestamp).format("HH:mm") : defaultValue;
    }


    animalFormatter = ({path, defaultValue}) => {
        // console.log(this, "DDD")
        const {animals} = this.dictionaries;
        return this._getDictionaryItemByID(animals, path, defaultValue);
    }

    efficiencyTimeFormatter = ({path, defaultValue}) => {
        return i18next.t("newSettings.dispenserNRF.efficiency.timeEfficiencyFormatter", {
            value: convertWeightUnitTo(get(this.log, path, defaultValue) * 2, {
                unit: UnitTypes.SMALL,
                fixed: 1,
                showUnit: true,
                acceptNil: true,
                rawValue: false
            })
        })
    }

    efficiencyImpulseFormatter = ({path, defaultValue}) => {
        return i18next.t("newSettings.dispenserNRF.efficiency.impulseEfficiencyFormatter", {
            value: get(this.log, path, defaultValue),
            unit: getUnit("volume", UnitTypes.MEDIUM, {overrideUnitSystem: "metric"})
        })
    }

    settingFormatter = ({path, defaultValue}) => {
        // console.log(this, "DDD")
        const {settings} = this.dictionaries;
        return this._getDictionaryItemByID(settings, path, defaultValue);
    }

    deviceFormatter = ({path, defaultValue}) => {
        // console.log(this, "DDD")
        const {devices} = this.dictionaries;
        return this._getDictionaryItemByID(devices, path, defaultValue);
    }

    employeeFormatter = ({path, defaultValue}) => {
        const {employees} = this.dictionaries;
        return this._getDictionaryItemByID(employees, path, defaultValue);
    }

    locationFormatter = ({path, defaultValue}) => {
        const {placements} = this.dictionaries;
        return this._getDictionaryItemByID(placements, path, defaultValue);
    }

    fallReasonFormatter = ({path, defaultValue}) => {
        const {F} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(F, path, defaultValue);
    }

    medicineFormatter = ({path, defaultValue}) => {
        const {MD} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(MD, path, defaultValue);
    }

    graftingReasonFormatter = ({path, defaultValue}) => {
        const {G} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(G, path, defaultValue);
    }

    noPregnancyFormatter = ({path, defaultValue}) => {
        const {N} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(N, path, defaultValue);
    }

    selectionReasonFormatter = ({path, defaultValue}) => {
        const {S} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(S, path, defaultValue);
    }

    dictionaryFormatter = ({path, defaultValue}) => {
        const mergedKeys = Object.values(this.dictionaries.dictionaries).reduce((a, b) => ({...a, ...b}), {});
        return this._getDictionaryItemByID(mergedKeys, path, defaultValue);
    }

    booleanFormatter = ({path, defaultValue}) => {
        const dict = {
            true: '✓',
            1: '✓',
            false: '✗',
            0: '✗'
        }
        return this._getDictionaryItemByID(dict, path, defaultValue);
    }

    skipDosesFormatter = ({path, defaultValue}) => {
        const dict = {
            true: '$t(skipped)',
            1: '$t(skipped)',
            false: '$t(ok)',
            0: '$t(ok)'
        }
        const doses = get(this.log, path, []);
        return doses.map((o, i) => `${i + 1}: ${dict[o] || "$t(none)"}`).join(", ")
    }

    weightFormatter = ({path, defaultValue}) => convertWeightUnitTo(get(this.log, path, defaultValue), {
        unit: UnitTypes.MEDIUM,
        showUnit: true,
        fixed: 1,
        acceptNil: true,
        rawValue: false
    })

    volumeFormatter = ({path, defaultValue}) => convertVolumeUnitTo(get(this.log, path, defaultValue), {
        unit: UnitTypes.MEDIUM,
        showUnit: true,
        fixed: 1,
        acceptNil: true,
        rawValue: false
    })

    animalKindFormatter = ({path, defaultValue}) => {
        const animalKind = get(this.log, path, defaultValue);
        return `$t(animalKind.${animalKind})`;
    }

    pregnantFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(events.usgEvent.${["negative", "positive", "toRepeat"][value]})`;
    }

    dictionaryTypeFormatter = ({path, defaultValue}) => {
        const dict = {};
        Object.values(DICTIONARY_TYPE).forEach(o => {
            const translation = getTranslationPath(o);
            if (translation !== null) {
                dict[o] = `$t(${translation})`;
            }
        })
        return this._getDictionaryItemByID(dict, path, defaultValue);
    }

    getFormatter = (formatterName) => {
        switch (formatterName) {
            case "animalFormatter":
                return this.animalFormatter;
            case "efficiencyTimeFormatter":
                return this.efficiencyTimeFormatter;
            case "efficiencyImpulseFormatter":
                return this.efficiencyImpulseFormatter;
            case "settingFormatter":
                return this.settingFormatter;
            case "employeeFormatter":
                return this.employeeFormatter;
            case "locationFormatter":
                return this.locationFormatter;
            case "fallReasonFormatter":
                return this.fallReasonFormatter;
            case "dateFormatter":
                return this.dateFormatter;
            case "timeFormatter":
                return this.timeFormatter;
            case "medicineFormatter":
                return this.medicineFormatter;
            case "graftingReasonFormatter":
                return this.graftingReasonFormatter;
            case "noPregnancyFormatter":
                return this.noPregnancyFormatter;
            case "weightFormatter":
                return this.weightFormatter;
            case "selectionReasonFormatter":
                return this.selectionReasonFormatter;
            case "animalKindFormatter":
                return this.animalKindFormatter;
            case "pregnantFormatter":
                return this.pregnantFormatter;
            case "deviceFormatter":
                return this.deviceFormatter;
            case "dictionaryFormatter":
                return this.dictionaryFormatter;
            case "dictionaryTypeFormatter":
                return this.dictionaryTypeFormatter;
            case "skipDosesFormatter": //todo: dorobic formatter do dawek
                return this.skipDosesFormatter;
            case "booleanFormatter":
                return this.booleanFormatter;
            case "volumeFormatter":
                return this.volumeFormatter;
            default:
                return ({path, defaultValue}) => isNil(get(this.log, path, defaultValue)) ? "$t(none)" : get(this.log, path, defaultValue);
        }
    }

    getIconAndName = () => {
        if ([LogTableTypes.DICTIONARY, LogTableTypesShort.DICTIONARY].includes(this.log.TableName)) {
            //trzeba ogarnac jaka ikonke wyswietlic a nie mamy w sumie nic poza id i name xD
            const {NAMES: {DICTIONARY, CONTROLLIST, MEDICINE}} = this.dictionaries.dictionaries;
            let icon = getIconClassName(LogTableTypes.DICTIONARY);
            if (CONTROLLIST[this.log.ObjID]) icon = getIconClassName("ControlList");
            if (MEDICINE[this.log.ObjID]) icon = getIconClassName("Medicine");
            const merged = {...DICTIONARY, ...CONTROLLIST, ...MEDICINE};
            return {
                name: (merged[this.log.ObjID]) || "$t(none)",
                icon: icon
            }
        } else {
            const tableTypes = [LogTableTypes.ANIMALS, LogTableTypes.SETTINGS, LogTableTypes.DEVICES, LogTableTypes.BUILDINGS];
            const tableTypesShort = [LogTableTypesShort.ANIMALS, LogTableTypesShort.SETTINGS, LogTableTypesShort.DEVICES, LogTableTypesShort.BUILDINGS];
            const idx = tableTypes.findIndex((o, i) => (o === this.log.TableName) || (tableTypesShort[i] && (tableTypesShort[i] === this.log.TableName)));
            const formatter = this.getFormatter(["animalFormatter", "settingFormatter", "deviceFormatter", "locationFormatter"][idx]);
            return {
                icon: getIconClassName(this.log.TableName),
                name: formatter({path: "ObjID"})
            };
        }

    }

    getData = () => {
        // console.log(this, "DDD")
        const {log} = this;
        const codes = log.Codes || [];
        const allParams = [];
        const allTranslations = [];
        codes.forEach((code, index) => {
            const params = (log.Params || [])[index] || {};
            const codeParams = {};
            for (let [key, {path, formatter, defaultValue = null}] of Object.entries(params)) {
                const valueFormatter = this.getFormatter(formatter);
                codeParams[key] = valueFormatter({path, defaultValue});
                // console.log(formatter);
            }
            allTranslations.push(`logsView.codes.${code}`);
            allParams.push(codeParams);
        })
        //get employee
        const user = {
            value: log.UserID,
            name: this.dictionaries.employees[log.UserID] || log.UserID,
            type: "user",
            isEmployee: !!this.dictionaries.employees[log.UserID]
        }
        const {icon, name} = this.getIconAndName();
        return {
            params: allParams,
            user,
            iconClassName: icon,
            translation: allTranslations,
            type: log.TableName,
            name: name
        };
    }

}
