import XLSXLibrary from "xlsx";
import Workbook from "./WorkBook";
import moment from "moment";
import _ from "lodash";

export default class XLSX {

    constructor(data, options = {
        headers: null,
        sheetName: null
    }) {
        this.workbook = new Workbook();
        let sheet;
        if (options.headers) {
            let headersMapped = options.headers.map(header => header.name);
            sheet = this.workbook.addSheet(options.sheetName, [headersMapped]);
        }
        if (!sheet) {
            this.workbook.addSheet(options.sheetName, data);
        } else {
            sheet.addData(data, "A2");
        }
    }

    save(fileName) {
        XLSXLibrary.writeFile(this.workbook, `${fileName}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.xlsx`);
    }

    static formatData(headers, data) {
        return data.map(d => {
            return headers.map(header => {
                if (d.isFullWidth) return _.get(d, header.field) || "";
                if (header.excelFormatter) return header.excelFormatter(_.get(d, header.field));
                return _.get(d, header.field);
            })
        });
    }
}
