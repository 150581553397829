import Bean from "./Bean"
import technologyGroupsDB from "../database/technologyGroupsDB";
import store from "../store/store"
import {getFarmStart, getTechnologyGroupWeeks} from "../utils/SettingsUtils";
import moment from "moment";
import {myID} from "../libs/generateID";
import {getCreateDay} from "../utils/TechnologyGroupUtils";
import _ from 'lodash';

/**
 * Klasa reprezentująca grupe technologiczną
 * @extends Bean
 */
class TechnologyGroup extends Bean {

    /**
     * Metoda tworzy nową grupę technologiczną.
     * Jeżeli znajdzie wcześniejszą grupę technologiczną to liczy czas wg niej. Jeżeli nie znajdzie to liczy
     * czas nowej względem startu fermy lub startu aktualnego roku.
     * @param time                  czas inseminacji
     * @return {TechnologyGroup}    nowa grupa technologiczna
     */
    static createTechnologyGroup(time) {
        let technologyGroupWeeks = getTechnologyGroupWeeks();
        let state = store.getState();
        let farm = state.location.farm;
        let startTime = 0;
        let technologyGroups = _.cloneDeep(technologyGroupsDB.getTechnologyGroups(farm));
        if (technologyGroups.length > 0) {
            technologyGroups.sort((tg1, tg2) => tg2.StartTime - tg1.StartTime);
            let groupToCalculate = technologyGroups.find(item => item.StartTime < time.toDate().getTime());
            console.log(groupToCalculate);
            if (groupToCalculate) {
                let sTime = moment(groupToCalculate.StartTime);
                console.log(sTime);
                let diff = time.diff(sTime, "days");
                let weeks = Math.floor(diff / 7);
                let modulo = weeks % technologyGroupWeeks;
                let weeksToAdd = weeks - modulo;
                let newStartTime = sTime.clone().add(weeksToAdd, "week");
                startTime = newStartTime.toDate().getTime();
            }
        }
        if (!startTime) {
            let groupStart = getFarmStart();
            groupStart.startOf("isoWeek");
            while (groupStart.toDate().getTime() < time.toDate().getTime()) {
                let tmp = groupStart.clone().add(technologyGroupWeeks, "week");
                if (tmp.toDate().getTime() > time.toDate().getTime()) {
                    startTime = groupStart.toDate().getTime();
                }
                groupStart = groupStart.add(technologyGroupWeeks, "week");
            }
            if (startTime === 0) startTime = time.toDate().getTime();
        }
        let start = moment(startTime).add(getCreateDay());
        console.log(start);
        let technologyGroup = {
            AnmList: [],
            DtaModTime: new Date().getTime(),
            FarmID: farm,
            Rmvd: [],
            RmvdCnt: 0,
            StartTime: +start,
            TGID: myID(),
            Ver: "1.01"
        };
        return new TechnologyGroup(technologyGroup);
    }

    /**
     * Metoda znajdująca grupe technologiczną dla podanego czasu.
     * Czas nie musi idealnie wskazywać na grupe technologiczną, zostanie znaleziona najbliższa w zakresie wybranym
     * tworzenia grup technologicznych, np. co 3 tygodnie
     * @param time                      czas inseminacji (moment)
     * @return {TechnologyGroup}        grupa technologiczna
     */
    static findTechnologyGroup(time) {
        let state = store.getState();
        let farm = state.location.farm;
        let technologyGroups = technologyGroupsDB.getTechnologyGroups(farm);
        let technologyGroupDays = getTechnologyGroupWeeks() * 7;
        time.startOf("day");
        for (let g of technologyGroups) {
            let gTime = moment(g.StartTime);
            console.log(+gTime);
            let diff = time.diff(gTime, "days");
            console.log(diff);
            if (diff < technologyGroupDays && diff >= 0) {
                return g;
            }
        }
    }

    /**
     * Metoda dodaje zwierzę do grupy technologicznej
     * @param AnmID     ID zwierzęcia, które ma być przypisane do grupy
     */
    addAnimal(AnmID) {
        if (!this.AnmList) this.AnmList = [];
        // jezeli zwierzę nie jest dodane do grupy technologicznej
        if (!this.AnmList.includes(AnmID)) {
            this.AnmList.push(AnmID);
            this.setDtaModTime();
            return;
        }
        // jeżeli zwierzę było usunięte z grupy technologicznej
        if (this.Rmvd.includes(AnmID)) {
            this.Rmvd = this.Rmvd.filter(item => item !== AnmID);
            this.setDtaModTime();
        }
    }

    /**
     * Funkcja dodająca liste ID zwierząt do grupy technologicznej
     * @param AnmIDs    lista ID zwierząt
     */
    addAnimals(AnmIDs) {
        for (let AnmID of AnmIDs) {
            this.addAnimal(AnmID);
        }
    }

    /**
     * Metoda usuwa zwierzę z grupy technologicznej
     * @param AnmID
     * @param permanently - w przypadku usuniecia inseminacji zwierze znika calkowicie z grupy tj. nie ma go w AnmList oraz w Rmvd
     */
    removeAnimal(AnmID, permanently = false) {
        // jeżeli lista usuniętych nie zawiera AnmID
        if (!this.AnmList.includes(AnmID)) throw new Error(`Zwierzę o ID ${AnmID} nie jest dodane do tej grupy technologicznej`);
        //w przeciwnym wypadku
        if (!this.Rmvd) this.Rmvd = [];
        if (!this.Rmvd.includes(AnmID)) {
            if (!permanently) this.Rmvd.push(AnmID);
            if (permanently) this.AnmList = this.AnmList.filter((id) => id !== AnmID);
            this.setDtaModTime();
        }
    }

    /**
     * Sprawdza czy data zawiera się w grupie technologicznej
     * @param date {number}     timestamp do sprawdzenia
     * @return {boolean}
     */
    includesDate(date) {
        const weeks = getTechnologyGroupWeeks();
        return moment(date).isBetween(this.StartTime, moment(this.StartTime).add(weeks, "weeks"), "day", "[]");
    }
}

export default TechnologyGroup;
