import Bean from "./Bean"
import {myID} from "../libs/generateID";
// import {getClass} from "../utils/EventUtils";
import store from "../store/store"
import {getGrIDByAnmID, getKillTime, KILL_TIME} from "../utils/EventUtils";

/**
 * Podstawowa klasa Event
 * @extends Bean
 */
class Event extends Bean {

    /**
     * Metoda tworzy obiekt eventu
     * @param AnmID {string}        ID zwierzęcia
     * @param EvCode {string}       Typ eventu
     * @param AnmCnt {number}       Ilość zwierząt, która dotyczyła eventu
     * @param EvTime {number}       Timestamp, w którym wykonano zdarzenie
     * @param EvData {object}       Dane zdarenia
     * @param Comment {string|null}      Komentarz do zdarzenia
     * @return {Transfer|Separation|NoPregnancy|USG|Parturition|*}  Nowy obiekt zdarzenia
     */
    static createEvent(AnmID, EvCode, AnmCnt, EvTime, EvData, Comment = null) {
        let state = store.getState();
        let FarmID = state.location.farm;
        let OperID = state.user.user.LocalUserID;
        let KillTime = getKillTime(KILL_TIME.THREE_YEARS);
        if (FarmID === "CypressFarm1" && OperID === "CypressLocal") {
            KillTime = getKillTime(KILL_TIME.ONE_DAY);
        }
        let GrID = getGrIDByAnmID(AnmID, EvTime);
        let event = {
            AnmID,
            EvCode,
            AnmCnt,
            EvTime,
            FarmID,
            OperID,
            EvData,
            EvID: myID(),
            DtaModTime: +new Date(),
            KillTime,
            GrID
        };
        if (Comment) event.Comment = Comment;
        if (!event.EvData) throw new Error("Brak EvData");
        const evUtils = require("../utils/EventUtils");
        return evUtils.getClass(event);
    }

    /**
     * Metoda oznaczająca Event jako usunięty
     * @param time {number} czas usunięcia Eventu
     */
    deleteEvent(time = +new Date()) {
        this.DtaDelTime = time;
        this.setDtaModTime();
    }

    /**
     * Przywraca usunięty Event
     */
    unDeleteEvent() {
        delete this.DtaDelTime;
        this.setDtaModTime();
    }
}

export default Event;