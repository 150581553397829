import Bean from "./Bean";
import store from "../store/store";
import {myID} from "../libs/generateID";
import animalsDB from "../database/animalsDB";

/**
 * Podstawowa klasa grup zwierząt
 * @extends Bean
 */
class Group extends Bean {

    /**
     * Metoda tworzy nowy obiekt grupy
     * @param GrNo1 {string}   numer grupy
     * @return {Group}          nowy obiekt grupy
     */
    static createGroup(GrNo1) {
        let state = store.getState();
        let FarmID = state.location.farm;
        return new Group({
            FarmID,
            AnmGrp: myID(),
            AnmIDs: [],
            DtaModTime: new Date().getTime(),
            Rmvd: [],
            GrNo1
        })
    }

    hasAnimalInGroup(AnmID) {
        return this.AnmIDs.includes(AnmID);
    }

    /**
     * Metoda dodaje zwierzę do grupy
     * @param AnmID {string}    ID zwierzęcia
     */
    addAnimalToGroup(AnmID) {
        if (!this.hasAnimalInGroup(AnmID)) {
            this.AnmIDs.push(AnmID);
            if (this.Rmvd.includes(AnmID)) {
                this.Rmvd = this.Rmvd.filter(item => item !== AnmID);
            }
        }
        this.setDtaModTime();
    }

    /**
     * Metoda usuwa zwierzę z grupy
     * @param AnmID {string}    ID zwierzęcia
     */
    removeAnimalFromGroup(AnmID) {
        if (this.hasAnimalInGroup(AnmID)) {
            this.AnmIDs = this.AnmIDs.filter(item => item !== AnmID);
            this.Rmvd.push(AnmID);
        }
        this.setDtaModTime();
    }

    /**
     * Metoda zwraca zsumowaną ilość zwierząt aktualnie istniejących w grupie
     * @return number {number}
     */
    getAnimalsNumber() {
        let sum = 0;
        if (!this.AnmIDs.length) return sum;
        for (let id of this.AnmIDs) {
            let animal = animalsDB.getAnimalById(id);
            if (animal) sum += animal.AnmCnt;
        }
        return sum;
    }

    getLocations() {
        let locations = new Set();
        for (let id of this.AnmIDs) {
            let animal = animalsDB.getAnimalById(id);
            if (animal) {
                let loc = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID);
                for (let l of loc) {
                    locations.add(l.location);
                }
            }
        }
        return [...locations.values()];
    }
}

export default Group;