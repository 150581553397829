import Bean from "./Bean"
import {myID} from "../libs/generateID";
import store from "../store/store"
import {SettingTypes} from "../constans/settingTypes";

// import {getSettingClass} from "../utils/SettingsUtils";

/**
 * Podstawowa klasa Setting
 * @extends Bean
 */
class Setting extends Bean {


    static createSetting(SetType, SetData, LocalUserID = null) {
        let state = store.getState();
        let FarmID = state.location.farm;
        let ClientID = state.user.user.ClientID;
        let SetID;
        switch (SetType) {
            case SettingTypes.GENERAL:
                SetID = ClientID;
                break;
            case SettingTypes.FARM_MAP:
                SetID = `farm_map_${FarmID}`;
                break;
            default:
                SetID = myID();
                break;
        }
        let setting = {
            ClientID,
            SetID,
            SetType,
            SetData,
            DtaModTime: +new Date()
        };
        if (SetType !== SettingTypes.GENERAL && SetType !== SettingTypes.NOTIFICATIONS) {
            setting.FarmID = FarmID;
        }
        if (LocalUserID) setting.LocalUserID = LocalUserID;
        if (!setting.SetData) throw new Error("Brak SetData");
        const setUtils = require("../utils/SettingsUtils");
        return setUtils.getSettingClass(setting);
    }

    /**
     * Metoda oznaczająca Setting jako usunięty
     * @param time {number} czas usunięcia Eventu
     */
    deleteSetting(time = +new Date()) {
        this.DtaDelTime = time;
        this.setDtaModTime();
    }

    /**
     * Przywraca usunięty Setting
     */
    unDeleteSetting() {
        delete this.DtaDelTime;
        this.setDtaModTime();
    }
}

export default Setting;