import Dictionary from "./Dictionary";
import {myID} from "../libs/generateID";
import {DICTIONARY_TYPE} from "../constans/general";
import store from "../store/store";

/**
 * Klasa reprezentująca program szczepien
 * @extends Dictionary
 */
class GraftingProgram extends Dictionary {

    /**
     * Metoda tworząca program szczepień
     * @param Name {string}             Nazwa programu szczepień
     * @param AnimalKind {number}       Typ zwierzęcia, dla którego stworzono program szczepień
     * @param MedicineList {Array<{Age: number, Medicine: string}>}    Lista szczepień
     */
    static createGraftingProgram(Name, AnimalKind, MedicineList) {
        const state = store.getState();
        const {user: {user: {ClientID}}} = state;
        let graftingProgram = new GraftingProgram({
            WordID: myID(),
            DtaModTime: new Date().getTime(),
            Type: DICTIONARY_TYPE.graftingProgram,
            ClientID,
            WData: {Name, AnimalKind, MedicineList}
        })
        graftingProgram.sortMedicines();
        return graftingProgram;
    }

    sortMedicines() {
        this.WData.MedicineList.sort((a, b) => a.Age - b.Age);
    }

}

export default GraftingProgram;